<template>
  <div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <button
          type="button"
          class="btn btn-primary"
          @click="addPath()"
          v-if="paths.length == 0"
        >
          Agregar Puntos
        </button>
        <button type="button" class="btn btn-danger" @click="removePath()">
          Eliminar Puntos
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <gmap-map
          :center="center"
          :zoom="17"
          style="width: 100%; height: 400px"
          ref="map"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="m.icon"
          ></gmap-marker>
          <gmap-polygon
            v-if="paths.length > 0"
            :paths="paths"
            :editable="true"
            @paths_changed="editarPoligono($event)"
            @rightclick="handleClickForDelete"
            ref="polygon"
          >
          </gmap-polygon>
        </gmap-map>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table
          id="geocercasPoli"
          class="table table-bordered table-striped table-hover table-sm"
        >
          <thead>
            <tr>
              <th>Posicion</th>
              <th>Longitud</th>
              <th>Latitud</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="puntos in paths[0]" :key="puntos.id">
              <td v-text="puntos.posicion"></td>
              <td v-text="puntos.lng"></td>
              <td v-text="puntos.lat"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  name: "SitioMapPoli",
  data() {
    return {
      form: {},
      latitud: null,
      longitud: null,
      center: { lat: 4.651371, lng: -74.071509 },
      markers: [],
      places: [],
      edited: null,
      paths: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: "",
    };
  },

  watch: {
    polygonPaths: function(paths) {
      if (paths) {
        this.paths = paths;
      }
    },
  },

  computed: {
    polygonPaths: function() {
      if (!this.mvcPaths) return null;

      let paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          let point = this.mvcPaths.getAt(i).getAt(j);
          path.push({
            id: j,
            lat: point.lat(),
            lng: point.lng(),
            posicion: j + 1,
          });
        }
        paths.push(path);
      }
      return paths;
    },
  },

  methods: {
    closeLoop(path) {
      return path.concat(path.slice(0, 1));
    },

    markerSitio() {
      this.latitud = this.$parent.$parent.latitud;
      this.longitud = this.$parent.$parent.longitud;
      const marker = {
        lat: parseFloat(this.latitud),
        lng: parseFloat(this.longitud),
      };
    },

    addPath: function() {
      var bounds = this.$refs.map.$mapObject.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.paths.length + 1;
      var f = Math.pow(0.66, degree);

      var path = [
        {
          lng: center.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
      ];

      this.paths.push(path);
    },

    removePath: function() {
      this.paths.splice(this.paths.length - 1, 1);
    },

    editarPoligono: function(mvcPaths) {
      this.mvcPaths = mvcPaths;
    },

    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },
    readGeojson: function($event) {
      try {
        this.polygonGeojson = $event.target.value;

        var v = JSON.parse($event.target.value);

        this.paths = v.coordinates.map((linearRing) =>
          linearRing
            .slice(0, linearRing.length - 1)
            .map(([lng, lat]) => ({ lat, lng }))
        );

        this.errorMessage = null;
      } catch (err) {
        this.errorMessage = err.message;
      }
    },


    initPaths() {
      if (this.$parent.formGeocerca.puntos !== undefined) {
        let puntos = this.$parent.formGeocerca.puntos;
        let paths = new Array();
        for (let i = 0; i < puntos.length; i++) {
          let punto = {
            lng: parseFloat(puntos[i]["longitud"]),
            lat: parseFloat(puntos[i]["latitud"]),
            posicion: parseFloat(puntos[i]["posicion"]),
          };
          paths.push(punto);
        }
        this.paths.push(paths);
  
        this.center = {
          lng: parseFloat(puntos[0]["longitud"]),
          lat: parseFloat(puntos[0]["latitud"]),
        };
      }
    },
  },

  mounted() {
    this.markerSitio();
    this.initPaths();
  },
};
</script>
