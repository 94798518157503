<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="nombre">Nombre</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="nombre"
            readonly="readonly"
            v-model="formGeocerca.nombre"
            :class="$v.formGeocerca.nombre.$invalid ? 'is-invalid' : 'is-valid'"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="tipo_geocerca">Tipo Geocerca</label>
          <select
            class="form-control form-control-sm"
            v-model="formGeocerca.tipo_geocerca"
            :class="
              $v.formGeocerca.tipo_geocerca.$invalid ? 'is-invalid' : 'is-valid'
            "
          >
            <option value="">Seleccione...</option>
            <option
              v-for="tipo_geocerca in listasForms.tipos_geocerca"
              :key="tipo_geocerca.numeracion"
              :value="tipo_geocerca.numeracion"
            >
              {{ tipo_geocerca.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="estado">Estado</label>
          <select
            class="form-control form-control-sm"
            v-model="formGeocerca.estado"
            :class="$v.formGeocerca.estado.$invalid ? 'is-invalid' : 'is-valid'"
          >
            <option value="">Seleccione...</option>
            <option
              v-for="estado in listasForms.estados"
              :key="estado.id"
              :value="estado.id"
            >
              {{ estado.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          class="btn btn-dark col-md-12 mt-4"
          v-show="!$v.formGeocerca.$invalid"
          @click="saveGeocerca()"
        >
          <i class="fas fa-save"></i>
        </button>
      </div>
    </div>
    <div class="row" v-if="formGeocerca != null">
      <div class="col-md-12">
        <puntos-gestion-map-poli
          v-if="formGeocerca.tipo_geocerca == 1"
          v-show="!$v.formGeocerca.$invalid"
          ref="geoPoli"
        />
        <puntos-gestion-map-cir
          v-if="formGeocerca.tipo_geocerca == 2"
          v-show="!$v.formGeocerca.$invalid"
          ref="geoCir"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import $ from "jquery";
import PuntosGestionMapPoli from "./PuntosGestionMapPoli.vue";
import PuntosGestionMapCir from "./PuntosGestionMapCir.vue";

export default {
  name: "SitioGeocerca",
  components: {
    PuntosGestionMapPoli,
    PuntosGestionMapCir,
  },
  data() {
    return {
      formGeocerca: {},
      estado: null,
      listasForms: {
        tipos_geocerca: [],
        estados: [],
      },
    };
  },

  validations: {
    formGeocerca: {
      tipo_geocerca: {
        required,
      },
      nombre: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    getTiposGeocerca() {
      axios.get("/api/lista/29").then((response) => {
        this.listasForms.tipos_geocerca = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    saveGeocerca() {
      this.$parent.cargando = true;
      this.formGeocerca.sitio_id = this.$route.params.id;

      if (this.formGeocerca.tipo_geocerca == 1) {
        this.formGeocerca.paths = this.$refs.geoPoli.paths[0];
        this.formGeocerca.longitud = this.$refs.geoPoli.paths[0][0].lng;
        this.formGeocerca.latitud = this.$refs.geoPoli.paths[0][0].lat;
      } else {
        this.formGeocerca.longitud = this.$refs.geoCir.reportedCenter.lng;
        this.formGeocerca.latitud = this.$refs.geoCir.reportedCenter.lat;
        this.formGeocerca.radio = this.$refs.geoCir.radius;
      }

      if (!this.$v.formGeocerca.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/puntosGestion/saveGeocerca",
          data: this.formGeocerca,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.formGeocerca.geocerca_id = response.data.id;
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    init() {
      if (this.$parent.geocerca != null) {
        this.formGeocerca = this.$parent.geocerca;
        this.formGeocerca.geocerca_id = this.$parent.geocerca.id;
      }
      this.formGeocerca.nombre = "GEOCERCA " + this.$parent.nombre;
    },
  },

  mounted() {
    this.getTiposGeocerca();
    this.getEstados();
    this.init();
  },
};
</script>
