import { render, staticRenderFns } from "./PuntosGestionGeocerca.vue?vue&type=template&id=19ea7daa&"
import script from "./PuntosGestionGeocerca.vue?vue&type=script&lang=js&"
export * from "./PuntosGestionGeocerca.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports